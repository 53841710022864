#maps, .maps
  width 100%
  height 500px

  &--fallback
    background $tsvLightGrey
    color $tsvBlack
    display flex
    align-items center
    justify-content center

  &__content
    display block

    &__headline
      font-size 1.6em
      display block
      font-family 'Work Sans', Helvetica Neue, Helvetica, Arial
      font-weight 700
      padding-bottom 5px

      @media screen and (max-width: $sWidth)
        font-size 1.4em

    &__text
      padding-bottom 15px
      font-weight 300
      display block

      strong, b
        font-weight 400
