.form-element
  position: relative;

  ~ .form-element {
    margin-top 1em
  }

  &__label
    font-size 1.1em
    font-weight 300
    display: block;
    height: 1.3em;
    line-height: 1.2;
    overflow: hidden;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    left: 2px;
    width: calc(100% - 4px);
    padding-top: 1px;
    background: white;
    box-sizing: border-box;
    top: 13px;
    transition: font-size 0.25s, height 0.25s, line-height 0.25s, top 0.25s;

    &--floating-label-visible
      bottom: calc(100% - 17px);
      top: 2px;
      padding-top: 1px;
      font-size: 0.8em;
      width: calc(100% - 10px);
      height: 17px;
      line-height: 17px;



  &__input
    height: 48px;
    line-height: 19px;
    padding: 10px;


  &__textarea
    padding: 10px 10px 15px;


  &__select
    background-color: #fff;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iRWJlbmVfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyNTZweCIgaGVpZ2h0PSI0MjAuNzY2cHgiIHZpZXdCb3g9IjAgMCAyNTYgNDIwLjc2NiIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjU2IDQyMC43NjYiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwb2x5bGluZSBmaWxsPSIjMzYzNjM2IiBwb2ludHM9IjEuOTYzLDE2NS4xODYgMTI4LjIwMiwwIDI1NC40NCwxNjUuMTg2ICIvPjxwb2x5bGluZSBmaWxsPSIjMzYzNjM2IiBwb2ludHM9IjI1NS41NDYsMjU0LjQ3NiAxMjguMjAzLDQyMC43NjYgMC44NTksMjU0LjQ3NSAiLz48L3N2Zz4=")
    background-position: right 7px center;
    background-repeat: no-repeat;
    background-size: 10px 20px;
    height: 48px;
    overflow: hidden;
    padding: 0.5em 25px 0.5em 0.5em;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--floating-label-visible
      background-position: right 7px bottom 3px;
      padding: 0.5em 25px 0.25em 0.5em;
      overflow visible

  &__select[multiple]
    padding 14px 10px 15px
    height auto
    background-image none
    overflow-x auto

    option
      font-size 0.75em

  &__input,
  &__textarea,
  &__select
    border: 2px solid $tsvLightGrey
    font-size 1.1em
    font-weight 300
    appearance: none;
    box-shadow: none;
    box-sizing: border-box;
    transition: border 0.5s;
    width: 100%;
    max-width: 100%;
    min-width: 65px;
    outline: 0;
    line-height: 1;
    border-radius: 0;

    &:focus
      border-bottom-color: $tsvLightBlue;

  &__input,
  &__textarea
    padding 0.5em

    &::placeholder
      opacity: 0;
      transition: opacity 0.25s;

    &--floating-label-visible
      padding-bottom: 5px;
      padding-top: 20px;

      &::placeholder
        opacity: 1;

  &__select + &__label
    width: calc(100% - 27px);

  &__checkbox
    display none

  &__checkbox-label
    width 100%
    position relative
    padding-left 30px
    display block

    &::before,
    &::after
      content " "
      position absolute
      left 0
      top 0.05em
      width 1.25em
      height 1.25em
      background-size 100% auto
      background-position center center
      background-repeat no-repeat
      transition opacity 0.25s

    &::before
      background-image url(../images/checkbox-unchecked.svg)
      z-index 1

    &::after
      background-image url(../images/checkbox-checked.svg)
      z-index 2
      opacity 0

  &__checkbox:checked ~ &__checkbox-label::after
    opacity 1


  &--error
    .form-element
      &__input,
      &__textarea,
      &__select
        border-color $tsvRed
      &__checkbox-label
        color $tsvRed
        &::before
          background-image url(../images/checkbox-unchecked-error.svg)
        &::after
          background-image url(../images/checkbox-checked-error.svg)

