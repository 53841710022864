.build-blog
  height calc(100vh - 250px)
  background url("../images/background/baublog-bg.gif")
  background-size cover
  background-position center center
  background-attachment fixed
  overflow-y: auto;

  &__timeline
    position relative
    padding 15px

    &::after
      content ""
      width 5px
      left 190px
      top 0
      bottom 0
      position absolute
      display block
      background $tsvDarkBlue

      @media screen and (max-width: 750px)
        left 15px

    &__event
      display flex
      flex-direction row
      align-items flex-start
      justify-content flex-start
      position relative

      @media screen and (max-width: 750px)
        flex-direction column

      &::before
        content ""
        position absolute
        top 8px
        left 170px
        height 12px
        width 12px
        background $tsvWhite
        border 2px solid $tsvDarkBlue
        border-radius 50%
        z-index 1

        @media screen and (max-width: 750px)
          left -5px

      & + &
        margin-top 15px

      &__content
        padding-left 50px

        @media screen and (max-width: 750px)
          padding 0
          margin-left 25px

        &__wrapper
          border 3px solid $tsvDarkBlue
          background $tsvDarkBlue
          min-width: 500px;
          width: 70%;

          @media screen and (max-width: 750px)
            width 100%

          &__image
            width 100%
            height auto
            display block

          &__title,
          &__text
            color $tsvWhite
            padding 3px 5px

          &__title
            font-size 2em
            font-weight bold

      &__date
        flex 0 0 155px
        width 155px
        padding 4px 5px
        color $tsvWhite
        background $tsvDarkBlue
        position relative
        white-space nowrap
        max-height 30px

        @media screen and (max-width: 750px)
          margin-left 25px
          width calc(100% - 25px)

        &::after
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 15px 0 15px 15px;
          border-color: transparent transparent transparent $tsvDarkBlue;
          content ""
          position absolute
          top 0
          right -15px

          @media screen and (max-width: 750px)
            border-width 15px 15px 15px 0;
            border-color transparent $tsvDarkBlue transparent transparent
            left -15px
            right auto