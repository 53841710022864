@import "https://fonts.googleapis.com/css?family=Raleway:400,100,200,700|Open+Sans:300,400,700,400italic|Work+Sans:100,300,400,600,700|La+Belle+Aurore&subset=latin,latin-ext";

*
  margin 0
  padding 0
  box-sizing border-box
  border-radius 0
  font-family: 'Open Sans', 'Arial', sans-serif;

@import "var"
@import "func"
@import "blocks/**/*"

body
  background url("../images/background/2560.jpg")
  background-size cover
  background-position center center
  background-attachment fixed
  transition: all ease-in-out 500ms
  @media screen and (max-width: 1920px)
    background-image url("../images/background/1920.jpg")
  @media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 1920px),
  only screen and (   min--moz-device-pixel-ratio: 2)      and (max-width: 1920px),
  only screen and (     -o-min-device-pixel-ratio: 2/1)    and (max-width: 1920px),
  only screen and (        min-device-pixel-ratio: 2)      and (max-width: 1920px),
  only screen and (                min-resolution: 192dpi) and (max-width: 1920px),
  only screen and (                min-resolution: 2dppx)  and (max-width: 1920px)
    background-image url("../images/background/3840.jpg")
  @media screen and (max-width: 1366px)
    background-image url("../images/background/1366.jpg")
  @media screen and (max-width: 1366px) and (orientation: landscape)
    background-image url("../images/background/1366.jpg")
  @media screen and (max-width: 1366px) and (orientation: portrait)
    background-image url("../images/background/1920.jpg")
  @media screen and (max-width: 800px)
    background-image url("../images/background/800.jpg")
  @media screen and (max-width: 800px) and (orientation: landscape)
    background-image url("../images/background/800.jpg")
  @media screen and (max-width: 800px) and (orientation: portrait)
    background-image url("../images/background/1920.jpg")
  @media screen and (max-width: 640px)
    background-image url("../images/background/640.jpg")
  @media screen and (max-width: 640px) and (orientation: landscape)
    background-image url("../images/background/640.jpg")
  @media screen and (max-width: 640px) and (orientation: portrait)
    background-image url("../images/background/1366.jpg")
  @media screen and (max-width: 360px) and (orientation: portrait)
    background-image url("../images/background/1366.jpg")

body.no-move
  height 100vh
  overflow hidden


.scrollStop
  overflow-y hidden
  height 100vh
  width 100vw
  max-height 100vh

a
  color inherit
  text-decoration underline
  font-weight inherit
  font-style inherit

.invisible
  display none