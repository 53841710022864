#hero, .hero
  display block
  position relative
  overflow-x hidden
  width 100wv

  &::after
    imageOverlay()
    content ""
    z-index 6

  &__slider
    width 100vw
    overflow hidden
    display block
    margin 0
    padding 0
    position relative

    &::before
      content ""
      padding-top 25%
      display block

      @media screen and (max-width: $mWidth)
        padding-top 33.333%

      @media screen and (max-width: $sWidth)
        padding-top 50%

    &__elem
      display: block
      width 100vw
      height 100%
      background-position center
      background-size cover
      background-repeat no-repeat
      position absolute
      left 0
      top 0

    &__content
      position absolute
      bottom 0
      left 100vw
      width 100vw
      z-index 7
      padding 1em
      padding-bottom 2em

      @media screen and (max-width: $sWidth)
        font-size 0.6em

      &__text, &__subText
        display block
        color $tsvWhite
        font-family 'Work Sans', Helvetica Neue, Helvetica, Arial

      &__text
        font-size 2.7em
        font-weight 400

      &__subText
        font-size 1.35em
        margin-top 1em
        font-weight 200

.slider-content-labels
  display flex
  width 100vw
  align-items stretch
  justify-content center
  background $tsvDarkBlue
  border-top 2px solid $tsvWhite
  position relative

  @media screen and (max-width: $sWidth)
    flex-direction column

  &::before
    content ""
    top 0
    left 0
    right 0
    display block
    background $tsvDarkBlue
    position absolute
    z-index 10
    height 13px
    pointer-events none

  &::after
    content ""
    bottom 0
    left 0
    right 0
    display block
    background $tsvDarkBlue
    position absolute
    z-index 10
    height 13px
    pointer-events none

  @media screen and (max-width: $sWidth)
    &::before,
    &::after
      content none

  &__label
    flex 1 1 auto
    max-width 300px
    text-align center
    color $tsvWhite
    text-decoration none
    padding 13px
    background $tsvDarkBlue
    position relative
    display block

    @media screen and (max-width: $sWidth)
      max-width none

    &::before,
    &::after
      content ""
      width 0
      height 0
      border-style solid
      display block
      position absolute
      top 0
      left 50%
      transform translateX(-50%)
      transition top 0.5s ease-in-out

    &::before
      border-width 0 15px 10px 15px
      border-color transparent transparent $tsvDarkBlue transparent
      z-index 9

    &::after
      border-width 0 19px 13px 19px
      border-color transparent transparent $tsvWhite transparent
      z-index 8

    @media screen and (max-width: $sWidth)
      &::before,
      &::after
        content none

    &--active::before
      top -10px

    &--active::after
      top -13px

    & + &
      border-left 1px solid $tsvWhite

      @media screen and (max-width: $sWidth)
        border-top 1px solid $tsvWhite
        border-left 0

.slider-content-labels ~ .content .content__wrapper
  min-height initial
