#news, .news
  &__articles
    width 100%
    display block
    margin 0
    padding 0
    list-style none
    margin-bottom -3px

  &__articleWrapper
    display block
    border-bottom 2px solid $tsvWhite
    &:last-of-type
      border 0

  &__article
    display block
    background-position center center
    background-size 100% auto
    background-repeat no-repeat
    position relative
    transition: background-size 2s, filter 2s
    transition-timing-function: ease;
    text-decoration none

    @media screen and (max-width: $mWidth)
      background-size 150% auto

    &::before
      content ""
      display block
      padding-top 33%

      @media screen and (max-width: $mWidth)
        padding-top 50%

    &::after
      content ""
      imageOverlay()

    &:hover
      background-size 133% auto

      @media screen and (max-width: $mWidth)
        background-size 100% auto

  &__articleHeadline
    position absolute
    padding 10px 20px
    font-size 2.7em
    color $tsvWhite
    left 0
    right 0
    bottom 0.6em
    font-weight 400
    z-index 1
    font-family 'Work Sans', Helvetica Neue, Helvetica, Arial

    @media screen and (max-width: $mWidth)
      font-size 1.4em

    &__category
      font-size 0.3em
      display block
      font-weight 200

      @media screen and (max-width: $mWidth)
        font-size 0.4em
        font-weight 400

  &__releaseDate
    position absolute
    bottom 0
    left 0
    color $tsvWhite
    padding 10px 20px
    text-decoration none
    z-index 1
    font-family 'Work Sans', Helvetica Neue, Helvetica, Arial
    font-weight 200

    @media screen and (max-width: $mWidth)
      font-size 0.7em
      font-weight 400