.event
  &__headlineBlocks
    text-align center
    position relative
    display table
    margin 1em auto 2em
  &__headlineBlock
    text-align center
    display table-row-group
    padding 0.05em
    font-size 1.5em
    font-weight 400
    color $tsvWhite
    background-image url(../images/button_bg.jpg)
    align-self center
    font-family 'Open Sans', 'Arial', sans-serif
    &:before, &:after
      display inline-block
      width 0.5em
      content ""
    &:first-child, &:last-child
      background none
      color $tsvDarkBlue
      font-weight 400
      font-size 0.55em
      padding 0
      &:before, &:after
        display none
    &:first-child
      text-align left
    &:last-child
      text-align right