.navigation
  list-style none
  padding 0
  margin 0
  @media screen and (max-width: $xlWidth)
    display none
  &__elem
    display inline-block
    a
      display block
      padding 5px 10px
      color $tsvWhite
      text-decoration none
      margin-bottom 2px
      margin-left -5px
      transition none
      position relative
      &:hover
        border-bottom 2px solid rgba(255, 255, 255, 0.10)
        margin-bottom 0
  &__notify
    position absolute
    border 1px solid $tsvWhite
    font-size 0.8em
    background-color $tsvDarkBlue
    borderRadius(10px)
    text-align center
    height 18px
    width 18px
    line-height 1
    padding-top 2px
    top 0px
    right -1px
    z-index 2
    boxShadow()
#hamburger, .hamburger
  overflow hidden
  position fixed
  top 0
  left 0
  bottom 0
  right 0
  z-index 100
  background url("../images/background/3840.jpg")
  background-size cover
  background-position center center
  padding 20px
  opacity 0
  display none
  @media screen and (min-width: $xlWidthPlus1)
    display none
  &__toogle
    position absolute
    z-index 101
    top 20px
    right 20px
    display block
    text-decoration none
    border 2px solid $tsvWhite
    padding 10px
    @media screen and (min-width: $xlWidthPlus1)
      display none
    @media screen and (max-width: $mWidth)
      border 0
  &__toogleLable
    font-size 16px
    margin-right 10px;
    color $tsvWhite
    text-decoration none
    position relative
    display block
    float left
    margin-right 20px
    @media screen and (max-width: $mWidth)
      display none
  &__toggleIcon
    width 20px
    height 20px
    position relative
    display block
    float left
    span
      height 2px
      background-color $tsvWhite
      display block
      position absolute
      width 20px
      &:nth-of-type(1)
        top 0
      &:nth-of-type(2)
        top calc(50% - 1px)
      &:nth-of-type(3)
        bottom 0
  &__logo
    height 120px
    width 120px
    background-image url("../images/logo.svg")
    background-size 112px auto
    background-position center center
    background-repeat no-repeat
    background-color $tsvDarkBlue
    -webkit-border-radius 120px;
    -moz-border-radius 120px;
    border-radius 120px;
    position relative
    z-index 1
    @media screen and (max-width: $mWidth)
      height 100px
      width 100px
      background-size 93px auto
  &__logoWrapper
    position relative
    z-index 0
    float left
    @media screen and (max-width: $mWidth)
      float none
      margin 0 auto
      display block
      width 100px
    &:before
      transform: rotate(-45deg)
      display block
      width 120px;
      height 200px
      content ""
      background -moz-linear-gradient(top, rgba(0,0,0,0.11) 0%, rgba(0,0,0,0) 100%);
      background -webkit-linear-gradient(top, rgba(0,0,0,0.11) 0%,rgba(0,0,0,0) 100%);
      background linear-gradient(to bottom, rgba(0,0,0,0.11) 0%,rgba(0,0,0,0) 100%);
      margin-left 66px
      margin-top 26px
      position absolute
      @media screen and (max-width: $mWidth)
        width 100px
        margin-left 66px
        margin-top 16px
  &__navigation
    width calc(100% + 12px)
    overflow-y scroll
    overflow-x hidden
    position absolute
    top 0
    left 180px
    list-style none
    height 100vh
    @media screen and (max-width: $mWidth)
      position absolute
      top 160px
      left auto
      height calc(100vh - 160px)
  &__elem
    font-family 'Work Sans', Helvetica Neue, Helvetica, Arial
    font-size 2em
    font-weight 100
    display block
    position relative
    &:first-of-type
      margin-top 180px
      @media screen and (max-width: $mWidth)
        margin-top 0
    &:last-of-type
      margin-bottom 50px
    a
      padding 15px 0;
      display block
      color $tsvWhite
      text-decoration none
      position relative
  &__notify
    position absolute
    border 1px solid $tsvWhite
    font-size 0.6em
    background-color $tsvDarkBlue
    borderRadius(15px)
    text-align center
    height 25px
    width 25px
    line-height 1
    padding-top 2px
    top 5px
    z-index 102
    boxShadow()
