.formattedText
  padding 40px 20px
  max-width 810px
  margin 0 auto
  display block
  font-size 1.1em
  font-weight 300
  h1, h2, h3, h4, h5, h6
    font-family 'Work Sans', Helvetica Neue, Helvetica, Arial
    font-weight 700
    padding-bottom 5px
    font-size 1.1em
  h1
    font-size 2.2em
    padding-bottom 10px
    @media screen and (max-width: $sWidth)
      font-size 1.8em
  h2
    font-size 1.9em
    padding-bottom 7px
    @media screen and (max-width: $sWidth)
      font-size 1.6em
  h3
    font-size 1.6em
    @media screen and (max-width: $sWidth)
      font-size 1.4em
  h4
    font-size 1.3em
    @media screen and (max-width: $sWidth)
      font-size 1.2em
  p
    padding-bottom 15px
  strong, b
    font-weight 400
  ol, ul
    margin 15px 25px
    margin-right 0
  img
    max-width 100%
    height auto
  table
    width 100%
    border-collapse: collapse;
    border 1px solid alpha($tsvLightBlue, 0.2)
    margin 10px 0
    tr
      border-bottom 1px solid alpha($tsvLightBlue, 0.2)
      &:last-child-of-type
        border-bottom 0
    td, th
      border-right 1px solid alpha($tsvLightBlue, 0.2)
      padding 3px
      vertical-align top
      text-align left
      &:last-child-of-type
        border-right 0