#events, .events
  &__event
    width 100%
    position relative
    display block
    background-color $tsvDarkBlue
    background-size 100% auto
    background-position center center
    background-repeat no-repeat
    cursor pointer
    overflow hidden
    transition background-size 1s, background-color 1s
    &:hover
      background-color $tsvLightBlue
      background-size 133% auto
      @media screen and (max-width: $mWidth)
        background-color $tsvDarkBlue
        background-size 100% auto
    &:before
      content ""
      display block
      padding-top 33%
  &__name
    color $tsvWhite
    background-image url("../images/background/3840.jpg")
    background-size 100% auto
    background-repeat no-repeat
    background-position center center
    font-weight 300
    position absolute
    padding 10px 25px 10px 50px
    top 15%
    left -25px
    transform rotate(-10deg)
    @media screen and (max-width: $lWidth)
      transform rotate(0deg)
      padding 10px 25px 10px 25px
      left 0
      margin-right 100px
  &__calendar
    position absolute
    bottom 30px
    left 47px
    width 100px
    -webkit-box-shadow 14px 14px 17px 1px alpha($tsvBlack, 0.4), 9px 9px 0px 1px alpha(lightness($tsvDarkBlue, 25%), 0.9), -3px 9px 0px 1px alpha(lightness($tsvDarkBlue, 25%), 0.9)
    -moz-box-shadow 14px 14px 17px 1px alpha($tsvBlack, 0.4), 9px 9px 0px 1px alpha(lightness($tsvDarkBlue, 25%), 0.9), -3px 9px 0px 1px alpha(lightness($tsvDarkBlue, 25%), 0.9)
    box-shadow 14px 14px 17px 1px alpha($tsvBlack, 0.4), 9px 9px 0px 1px alpha(lightness($tsvDarkBlue, 25%), 0.9), -3px 9px 0px 1px alpha(lightness($tsvDarkBlue, 25%), 0.9)
    transform rotate(-10deg)
    border-radius 3px
    -moz-border-radius 3px
    -webkit-border-radius 3px
    @media screen and (max-width: $lWidth)
      bottom 0
      top 0
      right 0
      left auto
      transform rotate(0deg)
      -webkit-box-shadow none
      -moz-box-shadow none
      box-shadow none
      border-radius 0
      -webkit-border-radius 0
      -moz-border-radius 0
  &__month
    position relative
    background-image url("../images/background/3840.jpg")
    background-size 100% auto
    background-repeat no-repeat
    background-position center center
    font-weight 300
    color $tsvWhite
    text-align center
    padding 3px 0
    font-size 1.1em
    border-top-left-radius 3px
    border-top-right-radius 3px
    box-shadow 3px 3px 0px 0px alpha(lightness($tsvDarkBlue, 40%), 0.9)
    @media screen and (max-width: $lWidth)
      -webkit-box-shadow none
      -moz-box-shadow none
      box-shadow none
      border-radius 0
      -webkit-border-radius 0
      -moz-border-radius 0
  &__day
    position relative
    background-color $tsvWhite
    text-align center
    padding 0
    font-size 4em
    font-weight 300
    color $tsvDarkBlue
    border-bottom-left-radius 3px
    border-bottom-right-radius 3px
    box-shadow 3px 3px 0px 0px alpha(lightness($tsvWhite, 40%), 0.9)
    @media screen and (max-width: $lWidth)
      box-shadow none
      border-radius 0
      font-size 3em
  &__weekday
    font-size 0.25em
    margin-top -15px
    padding-bottom 5px
    @media screen and (max-width: $lWidth)
      font-size 0.33em
    margin-top -10px