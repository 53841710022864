.filter
  margin-bottom 20px
  background-color $tsvDarkBlue
  color $tsvWhite
  position relative
  &__headline
    font-size 1.2em
    margin-bottom 5px
  &__buttonArea
    height 1px
    overflow hidden
    transition height 1s ease-in-out
    &--visible
      padding 10px 20px 30px
      height auto
  .form__button
    color $tsvWhite
    border-color $tsvWhite
    border-width 2px
  .form__button--active
    color $tsvDarkBlue
    background-color $tsvWhite
    border-color $tsvWhite
    border-width 2px
  &__row
    display block
    padding-bottom 10px
    &:last-child
      padding-bottom 0
    &__label
      margin-bottom 5px
      font-size 0.75em
  &__showMoreButton
    text-align center
    position absolute
    left 50%
    transform translateX(-50%) translateY(-50%)

  &__showMoreButtonLabel
    position relative
    z-index 2
    background-color $tsvDarkBlue
    color $tsvWhite
    padding 2px 5px
    font-size 0.9em
    text-decoration none
    cursor pointer
    border 2px solid $tsvWhite
    transition all 0.5s ease-in-out
    &:hover
      border-color $tsvDarkBlue
