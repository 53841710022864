#header, .header
  height 160px
  padding 20px
  position relative
  width 100vw
  &--hero
    background url("../images/background/3840.jpg")
    background-size cover
    background-position center center
    border-bottom $tsvDarkBlue 2px solid
    overflow-y hidden
  &__logo
    height 120px
    width 120px
    background-image url("../images/logo.svg")
    background-size 112px auto
    background-position center center
    background-repeat no-repeat
    background-color $tsvDarkBlue
    -webkit-border-radius 120px;
    -moz-border-radius 120px;
    border-radius 120px;
    position relative
    z-index 1
    @media screen and (max-width: $mWidth)
      height 100px
      width 100px
      background-size 93px auto
  &__logoWrapper
    position relative
    z-index 0
    float left
    @media screen and (max-width: $mWidth)
      float none
      margin 0 auto
      display block
      width 100px
    &:before
      transform: rotate(-45deg)
      display block
      width 120px;
      height 200px
      content ""
      background -moz-linear-gradient(top, rgba(0,0,0,0.11) 0%, rgba(0,0,0,0) 100%);
      background -webkit-linear-gradient(top, rgba(0,0,0,0.11) 0%,rgba(0,0,0,0) 100%);
      background linear-gradient(to bottom, rgba(0,0,0,0.11) 0%,rgba(0,0,0,0) 100%);
      margin-left 66px
      margin-top 26px
      position absolute
      @media screen and (max-width: $mWidth)
        width 100px
        margin-left 66px
        margin-top 16px
  &__name
    float left
    margin-top 45px
    margin-left 15px
    position relative
    z-index 1
    text-decoration none
    @media screen and (max-width: $mWidth)
      margin 0
      float none
      text-align center
  &__hl
    font-size 30px
    color $tsvWhite
    font-family 'Work Sans', Helvetica Neue, Helvetica, Arial
    &--uthin
      font-weight 100
    &--thin
      font-weight 300
  &__navigation
    position absolute
    top 124px
    right 20px
    z-index 1