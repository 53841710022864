#eventLargeHero, .eventLargeHero
  display block
  position relative
  &__hero
    width 100vw
    height 33vh
    background-position center center
    background-size cover
    background-repeat no-repeat
    overflow-x hidden
    &--topHero
      height calc(100vh - 160px)
      margin-bottom -100px
      @media screen and (max-width: $lWidth)
        margin-bottom 0
  &__heroTextWrapper
    transform rotate(-10deg)
    display block
    margin-left -50px
    margin-top 60px
    position absolute
    z-index 10
  &__heroText
    display table
    position relative
    background-image url("../images/background/3840.jpg")
    background-size 100% auto
    background-repeat no-repeat
    background-position center center
    color: $tsvWhite
    font-family 'Work Sans', Helvetica Neue, Helvetica, Arial
    font-weight 200
    padding 15px 25px
    padding-left 100px
    font-size 1.4em
    margin-bottom 10px
    break-after always
    boxShadow()
  &__heroTitle
    font-size 2.3em
  &__heroSubTitle
    font-size 2.0em