#sportOffer, .sportOffer
  &__trainingTime
    display block
    font-size 0.8em
    font-weight 400
    margin -5px 0 10px

  &__instructor
    display flex
    width calc(100% + 40px)
    flex-direction row
    flex-wrap nowrap
    justify-content flex-start
    align-items flex-start
    margin-top 20px
    margin-left -40px

    @media screen and (max-width: $lWidth)
      width 100%
      margin-left 0

    @media screen and (max-width: $mWidth)
      justify-content center
      align-items center
      flex-direction column

    &--noImage
      width 100%
      margin-left 0

  &__instructorImage
    display block
    width 100px
    height 100px
    borderRadius(100px)
    margin-right 20px
    @media screen and (max-width: $mWidth)
      margin-right 0
      margin-bottom 20px

  &__instructorCV
    display block
    width 530px
    @media screen and (max-width: $lWidth)
      width 500px
    @media screen and (max-width: $mWidth)
      width 100%

  &__instructorContact
    margin-bottom 5px

  &__instructorName
    font-weight 400

    @media screen and (max-width: $mWidth)
      text-align center

  &__instructorMail
    @media screen and (max-width: $mWidth)
      text-align center

    &:before
      display inline-block
      content ""
      background-image url(../images/icon/mail.svg)
      background-repeat no-repeat
      background-size auto 0.75em
      background-position center bottom
      width 1.1em
      height 1.1em

  &__instructorPhone
    @media screen and (max-width: $mWidth)
      text-align center

    &:before
      display inline-block
      content ""
      background-image url(../images/icon/phone.svg)
      background-repeat no-repeat
      background-size auto 0.75em
      background-position center bottom
      width 1.1em
      height 1.1em

#offerOverview, .offerOverview
  advancedGrid(6, 5)

  &__offer
    background-repeat no-repeat
    background-position center center
    background-size 105% auto
    position relative
    transition background-size 1s
    -moz-transition background-size 1s
    -ms-transition background-size 1s
    -o-transition background-size 1s
    -webkit-transition background-size 1s
    transition-timing-function: ease;
    display block
    cursor pointer
    border 1px solid alpha($tsvLightBlue, 0.05)

    &:before
      content ""
      display block
      padding-top calc(33% +10px)

    &::after
      content ""
      imageOverlay()

    &:hover
      background-size 133% auto

      @media screen and (max-width: $mWidth)
        background-size 105% auto

    &--noImage
      background-color $tsvDarkBlue
      transition background-color 1s
      transition-timing-function: ease
      &:hover
        background-color $tsvLightBlue

  &__offer-details
    position absolute
    bottom 0
    padding 0.5em
    color $tsvWhite
    z-index 2

    &__name
      font-family 'Work Sans', Helvetica Neue, Helvetica, Arial
      font-weight 400
      font-size 1.5em

    &__details
      font-size 0.8em