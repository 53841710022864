#eventLongShadow, .eventLongShadow
  &__fontWrapper
    width 100vw
    margin 60px 0 10px
    text-align center
  &__font
    color $tsvWhite
    font-size 1.6em
    font-family 'Work Sans', Helvetica Neue, Helvetica, Arial
    font-weight 200
    letter-spacing -1.5px
    makelongshadow(lightness(rgb(64, 100, 176), 45%), 1000)
    &--worpswede
      font-weight 300
      letter-spacing -2.3px
.worpswedeMeeting
  advancedGrid(4)
  &__diziplin
    text-align center
    height 150px
    margin-top 75px
    &--ballThrow
      height 205px
      margin-top 20px
    &--longJump
      height 120px
      margin-bottom 30px
  &__athletImage
    width auto
    height 100%
    &--ballThrow
      padding-left 50px