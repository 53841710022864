#form, .form
  display block
  &__group
    display flex
    flex-direction row
    flex-wrap nowrap
    justify-content flex-start
    align-items center
    margin 20px 0
    @media screen and (max-width: $mWidth)
      display block
    &--choiceBox
      justify-content flex-end
      flex-direction row-reverse
      @media screen and (max-width: $mWidth)
        display flex
    &--textArea
      align-items flex-start
  &__buttonGroup
    width 100%
    display flex
    flex-direction row
    justify-content flex-start
    align-items center
    margin 20px 0
    &--singleRight
      justify-content flex-end
    &--LeftRight
      justify-content space-between
      @media screen and (max-width: $mWidth)
        justify-content flex-start
        flex-direction column
  &__label
    padding-right 10px
    font-size 1.1em
    max-width calc(33% - 10px)
    @media screen and (max-width: $mWidth)
      display block
      max-width none
      width 100%
      padding 0
    &--choiceBox
      max-width none
      padding-right 0
      padding-left 10px
    &--valide
      color $tsvGreen
    &--notValide
      color $tsvRed
  &__input
    font-size 1.1em
    font-weight 300
    padding 3px
    border 2px solid $tsvLightGrey
    background-color $tsvWhite
    @media screen and (max-width: $mWidth)
      display block
      width 100%
      margin-top 3px
    &:focus
      border-color $tsvLightBlue
    &--valide
      border-color $tsvGreen
    &--notValide
      border-color $tsvRed
  &__select
    -moz-appearance none
    -webkit-appearance none
    font-size 1.1em
    font-weight 300
    padding 3px
    border 2px solid $tsvLightGrey
    background-image url(../images/select_arrow.png)
    background-repeat no-repeat
    background-size auto 100%;
    background-position right center
    background-color $tsvWhite
    @media screen and (max-width: $mWidth)
      display block
      width 100%
    &--valide
      border-color $tsvGreen
      background-image url(../images/select_arrow-valid.png)
    &--notValide
      border-color $tsvRed
      background-image url(../images/select_arrow-invalid.png)
  &__textarea
    height 150px
    resize vertical
    border 2px solid $tsvLightGrey
    padding 3px
    font-size 1.1em
    @media screen and (max-width: $mWidth)
      display block
      width 100%
    &:focus
      border-color $tsvLightBlue
  &__button
    border 3px solid $tsvDarkBlue
    padding 3px 10px
    font-size 1.1em
    color $tsvDarkBlue
    text-decoration none
    display inline-block
    font-weight 300
    background none
    box-shadow: 0px 10px 6px -6px rgba(0,0,0,0.3)
    cursor pointer
    -webkit-transition all 0.5s ease-in-out
    -moz-transition all 0.5s ease-in-out
    -o-transition all 0.5s ease-in-out
    transition all 0.5s ease-in-out
    &:hover
      box-shadow: 0px 12px 8px -8px rgba(0,0,0,0.4)
    @media screen and (max-width: $mWidth)
      display block
      width 100%
      text-align center
      &:nth-of-type(1n+1)
        margin-top 20px
    &--submit
      color $tsvWhite
      background-image url(../images/button_bg.jpg)
      background-size auto 100%;
      background-repeat no-repeat
      background-position center center
      border 0
      padding 6px 10px
    &--active
      color $tsvWhite
      background-color $tsvDarkBlue
    &--location
      font-size 0.75em
      padding 2px 10px 4px 0
      &:before
        content ""
        display inline-block
        background-color $tsvDarkBlue
        background-size 60% auto
        background-position center center
        background-repeat no-repeat
        background-image url(../images/location-white.svg)
        width 32px
        height 32px
        margin-right 10px
        margin-top -5px
        margin-bottom -9px
        @media screen and (max-width: $mWidth)
          display block
          float left
      @media screen and (max-width: $mWidth)
        &:after
          clear both
    &--small
      font-size 0.8em
      padding 3px 5px
    &--cancel
      border-color $tsvRed

.form-notices
  display block
  box-shadow: 5px 5px 10px 0px alpha(lighten($shadow, 50%), 50%);
  margin 0 0 10px;

  &__note
    border-left 4px solid $tsvWhite
    padding 5px 10px

    &:last-of-type
      border-bottom 0

    &--error
      border-left-color $tsvRed
      background-color lighten($tsvRed, 95%);

.inactive-form
  position relative

  &__overlay
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    background-color alpha($tsvWhite, 0.8)

    &__content
      position relative
      background-color $tsvWhite
      text-align center
      margin 50px
      padding 25px
      box-shadow: 5px 5px 10px 0px alpha(lighten($shadow, 50%), 50%);