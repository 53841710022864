#lightbox, .lightbox
  position fixed
  background-color alpha($tsvDarkBlue, 0.75);
  z-index 1000
  top 0
  left 0
  bottom 0
  right 0
  display flex
  justify-content center
  align-items center
  transition all 1s ease
  &__innerWrapper
    width 75%
    max-width 740px
    padding 30px
    position relative
    background-color $tsvWhite
    background-repeat no-repeat
    -webkit-box-shadow 10px 10px 58px -9px rgba(0,0,0,0.22)
    -moz-box-shadow 10px 10px 58px -9px rgba(0,0,0,0.22)
    box-shadow 10px 10px 58px -9px rgba(0,0,0,0.22)
    @media screen and (max-width: $lWidth)
      width 90%
    @media screen and (max-width: $mWidth)
      width 100%
      padding 0
      padding-top 30px
  &__imageArea
    width 100%
    overflow-x hidden
    img
      width 100%
      height auto
      display block
  &__closeButton
    display block
    text-decoration none
    float right
    margin-bottom 3px
    font-size 1.25em
    margin-right -19px
    margin-top -27px
    cursor pointer
    color $tsvBlack
    @media screen and (max-width: $mWidth)
      margin-right 15px
    &:before
      content "x"
      font-weight 300
    &:hover
      color $tsvDarkBlue
  &__button
    position absolute
    z-index: 1001
    top 50%
    padding 10px 20px
    display block
    background-image url("../images/background/3840.jpg")
    background-size 100% auto
    background-repeat no-repeat
    text-decoration none
    color $tsvWhite
    box-shadow: 0px 10px 6px -6px rgba(0,0,0,0.3)
    &:hover
      box-shadow: 0px 12px 8px -8px rgba(0,0,0,0.4)
    &--hide
      display none
    &--prev
      left 0px
      &:before
        content "◀ Zurück"
    &--next
      right 0px
      &:before
        content "Nächstes ▶"
  &__loadingWrapper
    background -moz-linear-gradient(45deg, alpha($tsvWhite, 0) 0%, alpha($tsvWhite, 0) 45%, alpha($tsvWhite, 0.75) 50%, alpha($tsvWhite, 0) 55%, alpha($tsvWhite, 0) 100%)
    background -webkit-linear-gradient(45deg, alpha($tsvWhite, 0) 0%, alpha($tsvWhite, 0) 45%, alpha($tsvWhite, 0.75) 50%, alpha($tsvWhite, 0) 55%,alpha($tsvWhite, 0) 100%)
    background linear-gradient(45deg, alpha($tsvWhite, 0) 0%, alpha($tsvWhite, 0) 45%, alpha($tsvWhite, 0.75) 50%, alpha($tsvWhite, 0) 55%, alpha($tsvWhite, 0) 100%)
    position absolute
    top 0
    bottom 0
    left 0
    right 0
    z-index 1002
    width 100vw
    height 100vh
    opacity 0.4
    animation lightbox__loadingAnimationFrames 3s infinite
    -webkit-animation-timing-function ease-in-out
    animation-timing-function ease-in-out
    display: none
    &--show
      display block !important

@keyframes lightbox__loadingAnimationFrames
  0%
    transform translateX(-100vw)
  100%
    transform translateX(200vw)