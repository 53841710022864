#content, .content
  padding 20px 180px
  display: block
  @media screen and (max-width: $xlWidth)
    padding 20px 97px
  @media screen and (max-width: $lWidth)
    padding 20px 50px
  @media screen and (max-width: $sWidth)
    padding 20px 20px
  &__wrapper
    display block
    background-color $tsvWhite
    color $tsvDarkBlue
    min-height calc(100vh - 200px)
    position relative
    z-index: 5
    @media screen and (min-width: $xlWidthPlus1)
      max-width 1000px
      margin 0 auto