.retrospective
  background $tsvDarkBlue
  padding 2em
  display grid
  grid-gap 2em
  grid-template-columns repeat(auto-fill, minmax(250px,1fr))
  grid-auto-rows 0

  @media screen and (max-width: $lWidth)
    grid-template-columns repeat(2, 1fr)

  @media screen and (max-width: $mWidth)
    grid-template-columns repeat(1, 1fr)

  &__tile
    break-inside avoid
    border-radius 5px
    overflow hidden
    boxShadow()

    &__content
      background $tsvWhite
      margin-bottom 2em
      overflow hidden

      &__image
        position relative

        img
          max-width 100%
          height auto

      &__text
        padding 1em
