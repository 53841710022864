.youtube-video
  &--inactive
    display block
    background $tsvLightGrey
    color $tsvBlack
    position relative

    &::before
      content ""
      padding-top 56.25064935%
      display block

  &__content-wrapper
    display flex
    position absolute
    left 0
    right 0
    top 0
    bottom 0
    align-items center
    justify-content center

    &__content
      display block
      padding 2em

      &__headline
        font-size 1.6em
        display block
        font-family 'Work Sans', Helvetica Neue, Helvetica, Arial
        font-weight 700
        padding-bottom 5px

        @media screen and (max-width: $sWidth)
          font-size 1.4em

      &__text
        padding-bottom 15px
        font-weight 300
        display block

        strong, b
          font-weight 400
