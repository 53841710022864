#galleryOverview, .galleryOverview
  position relative
  advancedGrid(6, 5)
  &__gallery
    display block
    text-decoration none
    background-size 101% auto
    background-position center center
    background-repeat no-repeat
    transition all 1s
    cursor pointer
    &:before
      content ""
      display block
      padding-top 75%
  &__galleryName
    position absolute
    bottom 0
    left 0
    right 0
    background-image url("../images/background/3840.jpg")
    background-size 100% auto
    background-repeat no-repeat
    color $tsvWhite
    padding 5px 10px
    height auto
    transition all 1s
  &__gallery:hover &__galleryName
    height 33%
    @media screen and (max-width: $mWidth)
      height: auto

#singleGallery, .singleGallery
  position relative
  advancedGrid(6, 5)
  &__image
    display block
    background-position center center
    background-size cover
    cursor pointer
    &:before
      content ""
      display block
      padding-top 75%