borderRadius(radius)
  -webkit-border-radius radius;
  -moz-border-radius radius;
  border-radius radius;

boxShadow()
  -webkit-box-shadow 6px 6px 23px 3px rgba(0,0,0,0.2);
  -moz-box-shadow 6px 6px 23px 3px rgba(0,0,0,0.2);
  box-shadow 6px 6px 23px 3px rgba(0,0,0,0.2);

textShadow()
  -webkit-text-shadow 6px 6px 23px 3px rgba(0,0,0,0.2);
  -moz-text-shadow 6px 6px 23px 3px rgba(0,0,0,0.2);
  text-shadow 6px 6px 23px 3px rgba(0,0,0,0.2);

gridElemWidth(cellNumber, cellCount, marginEach=0)
  width "calc(((100% / %s) * %s) - (%spx * 2))" % (cellCount cellNumber marginEach)

advancedGrid(cellCount, marginEach=0)
  &__grid
    display flex
    justify-content flex-start
    align-items flex-start
    flex-direction row
    flex-wrap wrap
    width 100%
    position relative
    if marginEach > 0
      padding "%spx" % marginEach
  for gridElem in 1..cellCount
    &__grid{gridElem}
        advancedGridCell(gridElem, cellCount, marginEach)
    &__grid{gridElem}--s
      @media screen and (max-width: $sWidth)
        advancedGridCell(gridElem, cellCount, marginEach)
    &__grid{gridElem}--m
      @media screen and (max-width: $mWidth) and (min-width: $sWidthPlus1)
        advancedGridCell(gridElem, cellCount, marginEach)
    &__grid{gridElem}--l
      @media screen and (max-width: $lWidth) and (min-width: $mWidthPlus1)
        advancedGridCell(gridElem, cellCount, marginEach)
    &__grid{gridElem}--xl
      @media screen and (max-width: $xlWidth) and (min-width: $lWidthPlus1)
        advancedGridCell(gridElem, cellCount, marginEach)
    &__grid{gridElem}--xxl
      @media screen and (min-width: $xlWidthPlus1)
        advancedGridCell(gridElem, cellCount, marginEach)

advancedGridCell(gridElem, cellCount, marginEach)
  display block
  padding 0
  margin 0
  position relative
  if marginEach > 0
    margin "%spx" % marginEach
  gridElemWidth(gridElem, cellCount, marginEach)

textStroke(color)
  text-shadow: -1px -1px 0 color, 1px -1px 0 color, -1px 1px 0 color, 1px 1px 0 color;

makelongshadow(color, length=200)
  val = 0px 0px color
  for index in 1..length
    val = "%s, %spx %spx %s" % (val index index color)
  text-shadow val

imageOverlay()
  display block
  position absolute
  top 0
  left 0
  right 0
  bottom 0
  background: linear-gradient(to bottom, alpha($tsvWhite, 0.001) 0%, alpha(darken($tsvDarkBlue, 0.75), 0.95) 100%);
  pointer-events none
