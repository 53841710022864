#pagination, .pagination
  padding 40px 0
  list-style none
  width 100%
  text-align center
  @media screen and (max-width: $mWidth)
    position relative
    display -webkit-flex
    display flex
    -webkit-flex-direction row
    flex-direction row
    -webkit-justify-content center
    justify-content center
    width 100%
    padding 40px
  &__itemWrapper
    display inline-block
    margin-right -5px
    border 2px solid $tsvDarkBlue
    border-right 0
    @media screen and (max-width: $mWidth)
      margin 0
      display block
    &:last-of-type
      border-right 2px solid $tsvDarkBlue
  &__item
    padding 8px 17px
    color $tsvDarkBlue
    text-decoration none
    display block
    transition background-color 0.5s, color 0.5s
    &:hover
      color $tsvWhite
      background-color $tsvDarkBlue
    @media screen and (max-width: $mWidth)
      display none
      text-align center
    &--disable
      cursor default
      &:hover
        background none
        color $tsvDarkBlue
    &--active
      cursor default
      color $tsvWhite
      background-color $tsvDarkBlue

      a
        color $tsvWhite

      @media screen and (max-width: $mWidth)
        display block
        width 120px
        &:before
          content "Seite "
      @media screen and (max-width: $sWidth)
        width calc((100vw - 80px) / 3)
        padding-left 0
        padding-right 0
    &--prev
      @media screen and (max-width: $mWidth)
        display block
        width 120px
        color $tsvWhite
        background-color $tsvDarkBlue
        border-right 1px solid $tsvWhite
      @media screen and (max-width: $sWidth)
        width calc((100vw - 80px) / 6)
        padding-left 0
        padding-right 0
        text-indent 100%
        white-space nowrap
        overflow hidden
        background-image url(../images/arrow_left.svg)
        background-position center center
        background-size 25% auto
        background-repeat no-repeat
      &:before
        content "←"
        padding-right 5px
    &--next
      @media screen and (max-width: $mWidth)
        display block
        width 120px
        color $tsvWhite
        background-color $tsvDarkBlue
        border-left 1px solid $tsvWhite
      @media screen and (max-width: $sWidth)
        width calc((100vw - 80px) / 6)
        padding-left 0
        padding-right 0
        text-indent 100%
        white-space nowrap
        overflow hidden
        background-image url(../images/arrow_right.svg)
        background-position center center
        background-size 25% auto
        background-repeat no-repeat
      &:after
        content "→"
        padding-left 5px
