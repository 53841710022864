.sigle-hero-page
  width 100vw
  height calc(100vh - 160px)
  background-size cover
  background-repeat no-repeat
  background-position center center
  display flex
  align-items center
  justify-content center
  &--typo
    max-width 33.3333vw
    color $tsvWhite
    margin 0 auto
    display block
    font-size 1.1em
    font-weight 300
    @media screen and (max-width: $lWidth)
      max-width 66.666vw
    @media screen and (max-width: $mWidth)
      max-width none
      padding 0 20px
    h1
      font-family 'Work Sans', Helvetica Neue, Helvetica, Arial
      font-weight 400
      text-align center
