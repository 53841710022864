.textGrid
  width 100%
  display flex
  justify-content flex-start
  align-items flex-start
  flex-wrap wrap
  &__elem
    &--6
      gridElemWidth(6, 6)
      position relative
      background-position bottom right -20px
      background-size 100% auto
      background-repeat no-repeat
      @media screen and (max-width: $mWidth)
        gridElemWidth(3, 3)
    &--5
      gridElemWidth(5, 6)
      position relative
      background-position bottom right -20px
      background-size 100% auto
      background-repeat no-repeat
      @media screen and (max-width: $mWidth)
        gridElemWidth(3, 3)
    &--4
      gridElemWidth(4, 6)
      position relative
      background-position bottom right -20px
      background-size 100% auto
      background-repeat no-repeat
      @media screen and (max-width: $mWidth)
        gridElemWidth(3, 3)
    &--3
      gridElemWidth(3, 6)
      position relative
      background-position bottom right -20px
      background-size 100% auto
      background-repeat no-repeat
      @media screen and (max-width: $mWidth)
        gridElemWidth(3, 3)
      @media screen and (max-width: $sWidth)
        gridElemWidth(2, 2)
    &--2
      gridElemWidth(2, 6)
      position relative
      background-position bottom right -20px
      background-size 100% auto
      background-repeat no-repeat
      @media screen and (max-width: $mWidth)
        gridElemWidth(2, 3)
      @media screen and (max-width: $sWidth)
        gridElemWidth(2, 2)
    &--1
      gridElemWidth(1, 6)
      position relative
      background-position bottom right -20px
      background-size 100% auto
      background-repeat no-repeat
      @media screen and (max-width: $lWidth)
        gridElemWidth(1, 3)
      @media screen and (max-width: $sWidth)
        gridElemWidth(1, 2)
    &--6--fix
      gridElemWidth(6, 6)
      position relative
      background-position bottom right -20px
      background-size 100% auto
      background-repeat no-repeat
    &--5--fix
      gridElemWidth(5, 6)
      position relative
      background-position bottom right -20px
      background-size 100% auto
      background-repeat no-repeat
    &--4--fix
      gridElemWidth(4, 6)
      position relative
      background-position bottom right -20px
      background-size 100% auto
      background-repeat no-repeat
    &--3--fix
      gridElemWidth(3, 6)
      position relative
      background-position bottom right -20px
      background-size 100% auto
      background-repeat no-repeat
    &--2--fix
      gridElemWidth(2, 6)
      position relative
      background-position bottom right -20px
      background-size 100% auto
      background-repeat no-repeat
    &--1--fix
      gridElemWidth(1, 6)
      position relative
      background-position bottom right -20px
      background-size 100% auto
      background-repeat no-repeat
