.crosslauf-teaser
  display block
  min-height 300px
  padding 20px
  padding-left 165px
  background-image url("../images/background/crosslauf-bg.jpg")
  background-size cover
  background-position center center
  background-attachment fixed
  color $tsvWhite
  position relative

  @media screen and (max-width: $sWidth)
    padding-left 20px
    padding-top 165px
    font-size 0.75em

  &::before
    content ""
    display block
    position absolute
    top 20px
    left 20px
    height 125px
    width 125px
    background-image url("../images/icon/crosslauf.png")
    background-size cover
    background-position center center

    @media screen and (max-width: $sWidth)
      left 50%
      margin-left -62.5px

  &::after
    content ""
    imageOverlay()

  &__headline,
  &__text
    font-family 'Work Sans', Helvetica Neue, Helvetica, Arial
    z-index 1
    position relative

  &__headline
    font-size 2.7em
    font-weight 400

  &__text
    font-size 1.35em
    font-weight 200

  &__cta
    margin-top 2em
    z-index 1
    position relative
