.footer
  background alpha($tsvBlack, 0.25)
  box-shadow inset 0 26px 40px -27px rgba(0,0,0,0.75);
  display flex
  align-items center
  justify-content center

  &__container
    display flex
    justify-content space-between
    align-items stretch
    max-width 1720px
    flex-wrap wrap
    padding 20px

  &__box
    display block
    flex 1 1 0
    min-width 400px
    margin 10px
    position relative
    background-image url("../images/background/640.jpg")
    background-size cover
    background-position center center
    background-attachment fixed

    &__content
      background $tsvWhite
      margin 10px
      padding 10px
      height calc(100% - 20px)

      &__headline
        font-family 'Work Sans', Helvetica Neue, Helvetica, Arial
        font-weight 200
        font-size 1.9em
        padding-bottom 7px
        @media screen and (max-width: $sWidth)
          font-size 1.6em

      &__text
        p
          padding-bottom 15px
        strong, b
          font-weight 400
        ol, ul
          margin 15px 25px
          margin-right 0
        img
          max-width 100%
          height auto